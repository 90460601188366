html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  position: relative;
}

.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 99999 !important;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}
.snowflake:nth-child(1) {
  --size: 0.6vw;
  --left-ini: -4vw;
  --left-end: -7vw;
  left: 84vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(2) {
  --size: 0.8vw;
  --left-ini: 2vw;
  --left-end: -8vw;
  left: 9vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(3) {
  --size: 0.8vw;
  --left-ini: 6vw;
  --left-end: 1vw;
  left: 3vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(4) {
  --size: 0.6vw;
  --left-ini: -5vw;
  --left-end: 3vw;
  left: 8vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
}

.snowflake:nth-child(5) {
  --size: 0.8vw;
  --left-ini: 5vw;
  --left-end: -2vw;
  left: 58vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(6) {
  --size: 1vw;
  --left-ini: -6vw;
  --left-end: -7vw;
  left: 5vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(7) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: 8vw;
  left: 41vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(8) {
  --size: 0.4vw;
  --left-ini: 2vw;
  --left-end: 3vw;
  left: 68vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(9) {
  --size: 0.6vw;
  --left-ini: 5vw;
  --left-end: 2vw;
  left: 7vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
}

.snowflake:nth-child(10) {
  --size: 1vw;
  --left-ini: 7vw;
  --left-end: -8vw;
  left: 73vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(11) {
  --size: 0.6vw;
  --left-ini: 5vw;
  --left-end: 0vw;
  left: 26vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(12) {
  --size: 0.8vw;
  --left-ini: -8vw;
  --left-end: 8vw;
  left: 18vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(13) {
  --size: 0.8vw;
  --left-ini: 2vw;
  --left-end: 6vw;
  left: 54vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(14) {
  --size: 1vw;
  --left-ini: -2vw;
  --left-end: 3vw;
  left: 50vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(15) {
  --size: 0.6vw;
  --left-ini: -8vw;
  --left-end: 0vw;
  left: 86vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(16) {
  --size: 1vw;
  --left-ini: 10vw;
  --left-end: 0vw;
  left: 8vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(17) {
  --size: 0.6vw;
  --left-ini: -2vw;
  --left-end: -4vw;
  left: 36vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(18) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: 6vw;
  left: 4vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(19) {
  --size: 0.4vw;
  --left-ini: 1vw;
  --left-end: 5vw;
  left: 94vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(20) {
  --size: 0.8vw;
  --left-ini: -2vw;
  --left-end: 1vw;
  left: 62vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(21) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: 5vw;
  left: 5vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(22) {
  --size: 1vw;
  --left-ini: 3vw;
  --left-end: -6vw;
  left: 56vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(23) {
  --size: 0.2vw;
  --left-ini: -5vw;
  --left-end: 0vw;
  left: 21vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(24) {
  --size: 0.4vw;
  --left-ini: 7vw;
  --left-end: 10vw;
  left: 53vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(25) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: -6vw;
  left: 89vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(26) {
  --size: 1vw;
  --left-ini: 8vw;
  --left-end: 9vw;
  left: 30vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(27) {
  --size: 0.2vw;
  --left-ini: 1vw;
  --left-end: 10vw;
  left: 76vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(28) {
  --size: 0.8vw;
  --left-ini: -8vw;
  --left-end: 9vw;
  left: 83vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(29) {
  --size: 0.4vw;
  --left-ini: -9vw;
  --left-end: 2vw;
  left: 79vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(30) {
  --size: 0.2vw;
  --left-ini: -9vw;
  --left-end: -2vw;
  left: 70vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(31) {
  --size: 0.4vw;
  --left-ini: -6vw;
  --left-end: 1vw;
  left: 19vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(32) {
  --size: 0.8vw;
  --left-ini: 6vw;
  --left-end: -4vw;
  left: 78vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(33) {
  --size: 0.8vw;
  --left-ini: -8vw;
  --left-end: 2vw;
  left: 69vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(34) {
  --size: 0.6vw;
  --left-ini: 7vw;
  --left-end: 9vw;
  left: 14vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(35) {
  --size: 0.8vw;
  --left-ini: 10vw;
  --left-end: 0vw;
  left: 84vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(36) {
  --size: 1vw;
  --left-ini: 6vw;
  --left-end: 4vw;
  left: 76vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(37) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: 5vw;
  left: 18vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(38) {
  --size: 0.8vw;
  --left-ini: 5vw;
  --left-end: 10vw;
  left: 84vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(39) {
  --size: 0.6vw;
  --left-ini: 2vw;
  --left-end: 6vw;
  left: 39vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(40) {
  --size: 1vw;
  --left-ini: -8vw;
  --left-end: 0vw;
  left: 9vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(41) {
  --size: 1vw;
  --left-ini: 1vw;
  --left-end: 3vw;
  left: 33vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(42) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: 1vw;
  left: 10vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(43) {
  --size: 0.6vw;
  --left-ini: -7vw;
  --left-end: -6vw;
  left: 69vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -4s;
}

.snowflake:nth-child(44) {
  --size: 0.8vw;
  --left-ini: 7vw;
  --left-end: -4vw;
  left: 59vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(45) {
  --size: 0.8vw;
  --left-ini: 7vw;
  --left-end: 4vw;
  left: 21vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(46) {
  --size: 0.8vw;
  --left-ini: -8vw;
  --left-end: 2vw;
  left: 54vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(47) {
  --size: 1vw;
  --left-ini: 6vw;
  --left-end: 6vw;
  left: 18vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(48) {
  --size: 0.8vw;
  --left-ini: -3vw;
  --left-end: -6vw;
  left: 19vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(49) {
  --size: 1vw;
  --left-ini: 1vw;
  --left-end: -5vw;
  left: 95vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(50) {
  --size: 0.8vw;
  --left-ini: -3vw;
  --left-end: 2vw;
  left: 60vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}

/***/
#youtube {
  z-index: 2;
  display: block;
  width: 100px;
  height: 70px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: red;
  border-radius: 50%/11%;
  transition: transform 0.5s;
}

#youtube:hover,
#youtube:focus {
  transform: scale(1.1);
}

#youtube::before {
  content: "";
  display: block;
  position: absolute;
  top: 7.5%;
  left: -6%;
  width: 112%;
  height: 85%;
  background: red;
  border-radius: 9%/50%;
}

#youtube::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: 40px;
  width: 45px;
  height: 30px;
  border: 15px solid transparent;
  box-sizing: border-box;
  border-left: 30px solid white;
}

#youtube span {
  font-size: 0;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.banner-image-card{
  padding:10px 10px 30px 10px !important;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.144) !important;
  width: max-content !important;
}

.banner-image-description{
  font-weight: 300;
  font-size: 10px;
  padding: 5px;
  border-radius: 20px;
  background: rgba(4, 47, 238, 0.253);
  width: max-content;
  margin-top: 10px;
}

.banner-first-card{
  position: absolute;
  top: 20%;
  left: 15%;
}

.banner-second-card{
  position: absolute;
  top: 50%;
  left: -5%;
}

.banner-third-card{
  position: absolute;
  top: 45%;
  left: 41%;
}

.banner-four-card{
  position: absolute;
  top: 5%;
  left: 40%;
}

.banner-five-card{
  position: absolute;
  top: 5%;
  left: -5%;
}

.banner-six-card{
  position: absolute;
  top: 30%;
  left: 65%;
}


.main-banner-container{
  position: relative !important;
  min-height: 100vh !important;
  width: 50vw !important;
}

.banner-main-container-flex{
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100vw !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.banner-title-content{
  left: 0px !important;
  transform: translateX(-250px);
 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (max-width:1740px){
  .banner-title-content{
    left: 0px !important;
    transform: translateX(-100px);
  }
}

@media only screen and (max-width:1240px){
  .banner-title-content{
    left: 0px !important;
    transform: translateX(-0px);
  }
  .main-banner-container{
    transform:translateY(25%) !important;
  }
}

.banner-title-content-mobile{
  display:none;
}

@media only screen and (max-width:740px){
  .banner-title-content{
    left: 0px !important;
    transform: translateX(-0px);
  }
  .main-banner-container{
    transform:translateY(0%) !important;
    width:80vw !important;
    margin:auto !important;
  }

  .banner-title-content{
    display:none;
    transform:translateY(-400px);
  }
  .banner-title-content-mobile{
    display:block;
    transform:translateY(-350px);
    
  }
  .banner-title-content-mobile>button{
    border: none;
    outline: none !important;
    padding: 10px 20px !important;
    border-radius: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .banner-title-content-mobile>h1{
    letter-spacing: 5px ;
  }
}

@media only screen and (max-width:600px){
  .banner-title-content{
    left: 0px !important;
    transform: translateX(-0px);
  }
  .main-banner-container{
    transform:translateY(5%) !important;
    width:90vw !important;
    margin:auto !important;
    
  }

  .banner-title-content{
    display:none;
    transform:translateY(-400px);
  }
  .banner-title-content-mobile{
    display:block;
    transform:translateY(-350px);
    
  }
  .banner-title-content-mobile>button{
    border: none;
    outline: none !important;
    padding: 10px 20px !important;
    border-radius: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .banner-title-content-mobile>h1{
    letter-spacing: 5px ;
    font-size:25px !important;
  }
}

@media only screen and (max-width:500px){
  .banner-title-content{
    left: 0px !important;
    transform: translateX(-0px);
  }
  .main-banner-container{
    transform:translateY(10%) !important;
    width:100vw !important;
    margin:auto !important;
    
  }

  .banner-title-content{
    display:none;
    transform:translateY(-400px);
  }
  .banner-title-content-mobile{
    display:block;
    transform:translateY(-280px);
    
  }
  .banner-title-content-mobile>button{
    border: none;
    outline: none !important;
    padding: 10px 20px !important;
    border-radius: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .banner-title-content-mobile>h1{
    letter-spacing: 5px ;
    font-size:25px !important;
  }
}

.banner-title-content>h1{
  letter-spacing: 5px ;
}

.banner-title-content>button{
  border: none;
  outline: none !important;
  padding: 10px 20px !important;
  border-radius: 20px;
  margin-top: 20px;
  font-weight: 600;
}

.second-section-container{
  position:relative !important;
  // height: 100vh !important;
  height: max-content !important;
  width: 100vw !important;
  padding: 150px 0px !important;
  margin-top:-100px !important;
  z-index:9999 !important;
  // background-color: white !important;
}

.get-started-title{
  text-align: center !important;
  color: rgb(20,120,182) !important;
  font-weight: 600 !important;
}

.fourth-get-started-title{
  text-align: center !important;
  color: white !important;
  font-weight: 600 !important;
}


.second-section-middle-container{
  display: flex !important;
  flex-wrap: wrap;
  // align-items: center !important;
  justify-content: center !important;
  width: 100vw !important;
  justify-self: center !important;
  align-content: center !important;
  margin-top: 50px !important;
  gap: 100px !important;
  
}

.second-section-first-card, .second-section-second-card, .second-section-third-card{
  width: 300px;
  box-shadow:0px 0px 5px #00000030;
  border-radius:10px;
  padding:15px !important
}

.second-section-second-card:hover{
  cursor:pointer !important;
  box-shadow:0px 0px 5px #147fbf;
  // transform:scale(1);
}

.second-section-first-card:hover{
  cursor:pointer !important;
  box-shadow:0px 0px 5px #147fbf;
  // transform:scale(1);
}

.second-section-third-card:hover{
  cursor:pointer !important;
  box-shadow:0px 0px 5px #147fbf;
  // transform:scale(1);
}

.second-section-card-content{
  color: black !important;
  text-align: center !important;
}

.second-section-card-title{
  color: black !important;
  text-align: center !important;
  margin: 20px  0px !important;
}

.second-section-get-started-container{
  // width: 100vw !important;
  // display: flex !important;
  // justify-content: center !important;
}

.second-section-get-started{
  padding: 10px 20px ;
  border: none;
  outline: none;
  margin: 80px auto;
  // border-radius: 20px;
  // box-shadow: 0px 0px 5px grey;
  z-index:999999 !important;
}


// .second-section-get-started:hover{
//   cursor: pointer !important;
// }

.third-section-container{
  background-image: linear-gradient(to bottom, #ffffff, #eae9f7, #d2d3ef, #b8bfe8, #9babe1, #8599d3, #6f88c5, #5777b7, #46639f, #365087, #253e70, #142d5a) !important;
  height: max-content !important;
  margin-top:-200px;
}


.third-section-card-container{
  display: grid !important;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 60%;
  margin: 100px auto !important;
  gap: 100px !important;
  place-items:center !important;
}

@media screen and (max-width:1840px){
  .third-section-card-container{
    display: grid !important;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 80%;
    margin: 100px auto !important;
    gap: 100px !important;
    place-items:center !important;
  }
}

@media screen and (max-width:1440px){
  .third-section-card-container{
    display: grid !important;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 90%;
    margin: 100px auto !important;
    gap: 100px !important;
    place-items:center !important;
  }
}

@media screen and (max-width:1300px){
  .third-section-card-container{
    display: grid !important;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 90%;
    margin: 100px auto !important;
    gap: 100px !important;
    place-items:center !important;
  }
}

.third-section-card{
  padding: 50px 10px !important;
  width:100% !important;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.11) !important;
  transition:all .2s ease;
}

.third-card-content{
  margin-top: 10px;
  text-align: center !important;
  font-size:16px;
}

.third-section-card:hover{
  cursor:pointer;
transform:scale(1.05) !important;
box-shadow:0px 0px 5px #147fbf !important;
}

.fourth-section-container{
  background-image: linear-gradient(to bottom, #142d5a, #2d4c78, #476d96, #658fb4, #85b3d2, #96c2e1, #a7d1f0, #b8e0ff, #bddcff, #c5d7ff, #ced2ff, #d9cdfd) !important;
  height: max-content !important;
  margin-top:-50px;
}
.active-top-seller{
  color: white ;
  background:#147fbf59 ;
  border-radius:10px;
  padding:5px 10px;
}

.fourth-inner-container{
  width: 90% !important;
  margin: auto !important;
}

.fourth-sort-container{
  width: 80% !important;
  display: flex !important;
  justify-content: flex-end !important;
  gap: 20px !important;
}

.fourth-sort-btn{
  padding: 5px 20px !important;
  border-radius: 20px !important;
  border: none !important;
  outline: none !important;
  background-color: #3C5F89 !important;
  border: 1px solid #3C5F89 !important;
  color: white !important;
  background: #3C5F89;
box-shadow: inset -29px 29px 58px #3C5F89,
            inset 29px -29px 58px #406086;
}

.fourth-sort-btn:hover{
  cursor:  pointer !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid white !important;
  box-shadow: 0px 0px 5px grey !important;
}

.fourth-sort-btn-active{
  cursor:  pointer !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid white !important;
  box-shadow: 0px 0px 5px grey !important;
}



.top-collection-map-container{
 display:flex;
 align-items:center;
 gap:10px;
}

.top-collection-image{
  width:50px;
  border-radius:50%;
}

.top-collection-floor-flex{
  display:flex;
  align-items:center;
  justify-content:space-between;
  color: #040C1B !important;
}

.top-collection-volume-flex{
  display:flex;
  align-items:center;
  justify-content:space-between;
  color: #040c1b9f !important;
}

.top-collection-title{
  font-weight:600;
  font-size:15px;
  color: #040C1B !important;
}

.top-collection-floor-btn{
  border:none;
  outline:none;
  padding:3px 10px;
  border-radius:10px;
  margin-top:-8px;
  color: #040C1B !important;
}

.top-collection-floor-content{
  color: #040C1B !important;
  color: #ff000042;
}

.red{
  color:#00800060
}

.top-collection-map-container{
  width:100% !important;
}


.top-collection-main-map-container{
  display: grid !important;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 60%;
  margin: 100px auto !important;
  gap: 100px !important;
  place-items:center !important;
}

.fifth-section-container{
  // background-image: linear-gradient(to bottom, #d9cdfd, #ced2ff, #c5d7ff, #bddcff, #b8e0ff, #a7d1f0, #96c2e1, #85b3d2, #658fb4, #476d96, #2d4c78, #142d5a) !important;
  background:white !important;
  color:black !important;
}

.sixth-section-container{
  // background-color: rgb(21,46,91) !important;
  background:linear-gradient(180deg,white,#147FBF) !important;
  height: max-content !important;
}

.sixth-section-map-container{
  display: grid !important;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 60%;
  margin: 100px auto !important;
  gap: 50px !important;
  place-items:center !important;
}

.sixth-section-map-first-flex{
  display: flex !important;
  align-items: center !important;
  gap: 10px;
  
}

.sixth-section-content{
  display: flex !important;
  align-items: center ;
  justify-content: flex-start !important;
  gap: 30px;
  width: 60%;
  margin: auto !important;
}

.sixth-section-content > h5{
  cursor:  pointer !important;
  letter-spacing: 2px !important;
  padding:5px 10px;
  color:#152E5B !important;
}

.sixth-section-content > h5:hover{
  cursor:pointer;
  color: white ;
  background:#147fbf59 ;
  border-radius:10px;
  padding:5px 10px;
}

// .category-container{
//   padding: 10px !important;border-radius: 20px !;
// }

.sixth-section-map-inner-container{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  // background: grey !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  backdrop-filter: blur(16px) saturate(200%);
  -webkit-backdrop-filter: blur(16px) saturate(200%);
  background-color: rgba(17, 25, 40, 0.53);
  border-radius: 12px;
  width:100% !important;
  // border: 1px solid rgba(255, 255, 255, 0.125);
}

.seven-section-container{
  display: flex !important;
  align-items: center !important;
  justify-content: space-around;
  flex-wrap:wrap;
  gap:50px;
}

.get-started-btn{
  border: none;
  outline: none;
  background:#147fbf !important;
  padding: 10px 20px !important;
  border-radius: 10px;
  color: white !important;
  font-weight: 600;
}

.contact-sales-btn{
  border: none;
  outline: none;
  background:rgb(255, 255, 255);
  margin-left: 20px;
  padding: 10px 20px !important;
  border-radius: 10px;
  color: rgb(24, 24, 90);
  font-weight: 600;
}

// .scroll-container{
//   height: 100vh !important;
//   scroll-behavior: smooth !important;
// overflow-y: scroll !important;
// scroll-snap-type: y mandatory !important;
// }

// .section-first{
//   height: 100vh !important;
//   scroll-snap-align: start !important;
//   scroll-snap-stop: always !important;
//   object-fit: cover !important;
//   object-position: center !important;
// }

