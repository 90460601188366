
.modalBox{
  position: fixed !important;
  /* background-color: rgba(0, 0, 0, 0.788)  !important; */
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  z-index: 12335656562565443 !important;
  top: 0% !important;
}
.blackColor{
  color: black !important;
}

.modalBox_container{
    color: white !important;
    position: absolute !important;
    z-index: 23456787654 !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding:  20px !important; 
    border: 3px solid grey !important;
    box-shadow: 2px 2px 5px grey !important;
    border-radius: 20px !important;
   top: 50% !important;
   left:  50% !important;
   transform: translate(-50%,-50%) !important;
   background-color: black !important;
}


.loaderContainer{
    position: fixed !important;
  backdrop-filter: blur(2px) !important;
  background: rgba(0, 0, 0, 0.536) !important;
  top: 0% !important;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  z-index: 234567654 !important;
}

.loaderContainerInside{
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.profile_image_container{
  position: relative !important;
}

.tickMark{
  position: absolute !important;
  bottom: 0px !important;
}

.myCollection_cover{
  background-position: center !important;
  background-size: contain !important;
  background-attachment: fixed !important;
  background-origin: content-box !important;
}


.logoutContainer{
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
}


.passwordField{
outline: none !important;
border: none !important;
background-color: none !important;
}


.imageBoxContainer{
height:300px !important;
}

.imageBox{
position: relative !important;
width: 250px !important;
height:  250px !important;
}

.dropdownSelect{
width: 100% !important;
}

.noDataFound{
position: absolute !important;
left: 40% !important;
}



table {
  font-family: "ClashDisplay, sans-serif" !important;
border-collapse: collapse !important;
width: 100% !important;
}


th {
border: 1px solid #ddd !important;
text-align: center !important;
padding: 8px !important;
}

td{
text-align:left !important;
}

th {
background-color: rgb(131,100,226) !important;
color: white !important;
}

/* Responsive Design*/

@media (min-width: 770px) and (max-width: 1025px) {
.breadcumb.h-vh {
  height: 70vh !important;
  display: flex;
  align-items: center;
  background-position: 50%;
  background-size: cover;
}
.imageBox{
position: relative !important;
width: 180px !important;
height:  180px !important;
}
}

@media (min-width: 480px) and (max-width: 769px) {

.imageBox{
  position: relative !important;
  width: 180px !important;
  height:  180px !important;
}


}

/* expolre 2 */

.item_filter_group {
    /* border: solid 1px rgba(0, 0, 0, .15); */
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
}
.dark-scheme .item_filter_group {
  /* border: solid 1px rgba(255, 255, 255, .075); */
}

.item_filter_group h4 {
  font-size: 18px;
}

/* expolre 2 */


.display-2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 10px;
}