.eze_loader{
    animation: ezeLoader 15s ease infinite;
   }
   
   
   /* Chrome, Safari, Opera */
   @-webkit-keyframes ezeLoader {
       10%{
           transform: scale(1.1);
       }
       20%{
           transform: scale(1);
       }
       30%{
           transform: scale(1.1);
       }
       40%{
           transform: scale(1);
       }
       50%{
           transform: scale(1.1);
       }
       60%{
           transform: scale(1);
       }
       70%{
           transform: scale(1.1);
       }
       80%{
           transform: scale(1);
       }
       90%{
           transform: scale(1.1);
       }
       100%{
           transform: scale(1);
       }
       
   }
    
   /* Standard syntax */
   @keyframes ezeLoader {
       10%{
           transform: scale(1.1);
       }
       20%{
           transform: scale(1);
       }
       30%{
           transform: scale(1.1);
       }
       40%{
           transform: scale(1);
       }
       50%{
           transform: scale(1.1);
       }
       60%{
           transform: scale(1);
       }
       70%{
           transform: scale(1.1);
       }
       80%{
           transform: scale(1);
       }
       90%{
           transform: scale(1.1);
       }
       100%{
           transform: scale(1);
       }
       
   }
   /* .load_div{
    width:100%;
    height:100vh;
    display: flex;
    align-items: center;   
    justify-content: center;
    backdrop-filter: blur(5px);
   } */
   .imgloader{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    overflow:hidden;
    width:300px;
    height:300px;
    box-shadow:0px 0px 5px #00000030;
   border-radius: 10px;
 

 
   }
   .imgloader h6{
    width: 77%;
    line-height: 23px;
    text-align: center;
    color: #147fbf !important;
   }
   .imgloader .head{
    height: 20%;
    position:relative;
    top:  -43px;
    width: 100%;
    background-color:#147fbf ;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   }
   .imgloader img{
    width: 100px;
    height: 100px;
   }