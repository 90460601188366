.imgtextCell{
    display: flex;
    justify-content: flex-start;
    align-items: center;
   
}
.imgCell{
    
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 10px;
}
.imgCell img{
    height: 100%;
    width:100%;
    border-radius: 10px;
    object-fit: cover !important;
}
.popup {
  /* display: none; */
  /* position: absolute; */
  width: 350px;
  background-color: #fff;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid #ccc;
  top: 0;
  left: 0;
  z-index: 1;
}
.textCells:hover .popup {
  display: block;
}
.card-wrapper{
height: max-content;
}
.owl-nav{
    display: flex;
    position: relative;
    top: -175px;
    justify-content: space-between;
}
.owl-prev{
    border: 1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.owl-prev span{
    color:#134faf !important;

    font-size: 50px;
}
.owl-next{
    border: 1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.owl-next span{
    color:#134faf !important;

    font-size: 50px;
}
.owl-dots{
    display: none;
}

.imgcontainer{
  width: 100px;
  height: 100px;
  border:1px solid;
  margin:5px 5px 15px 5px;
  border-radius: 5px;
}
.imgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
/* .owl-carousel .owl-item .acc-btn .accept img,
.owl-carousel .owl-item .acc-btn .reject img {
  display: unset;
  width: unset;
} */

  .owl-stage-outer .owl-stage{ display: flex;
}
  .owl-theme .owl-dots .owl-dot.active span {
    background: #134faf !important;
  }
  .owl-theme .owl-dots .owl-dot span {
    background: #a6a6a6 !important;
  }
  .nft_text_wrap{
    padding:10px
  }
  .nft_flex_wrap{
  display: flex;
  justify-content: space-between;
  }
  .nft_flex_wrap .totals{
    text-align: end;
  }
  .nft_flex_wrap .totals h5{
    font-size: 17px !important;
  }
  .nft_flex_wrap .floors h5{
    font-size: 17px !important;
  }
  .nft_flex_wrap .floors{
    text-align: start;
  }
  @media (min-width:450px) and (max-width:550px){
    .owl-nav{
    top: -200px;
  }
}
@media (max-width:450px){
    .owl-nav{
    top: -250px;
  }
}

.checkout{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}