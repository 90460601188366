.sbanner-small {
    width: 201px;
    height: 200px;
    background-clip: border-box;
    background-size: cover;
    background-position: center !important;
    background-origin: border-box;
    background-repeat: no-repeat;
}


.iconpara {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#ssmallbanner {
    width: 220px;
    height: 220px;
    border: 10px solid white;
    align-content: center;
    text-align: center;
    position: absolute;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    bottom: -35px;
}

#ssmallbanner svg {
    color: white;
    font-size: 40px;
    position: absolute;
    top: 82px;
    left: 80px;
    z-index: 10;
   display: none;
}



#ssmallbanner:hover .sbanner-small {
    filter: blur(1px);
  
}

#ssmallbanner:hover svg {
    display: block;
   
}
.flex-container{
    display: flex;
    flex-wrap: wrap;
}
#sidebarfilter{
    border-right: 2px solid gray;
}
.flexcontainer{
    display: flex;
    flex-wrap: wrap;
}
.imagediv{
    height:max-content;
}

.image-griding {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the width as needed */
    grid-gap: 20px; /* Adjust the gap between images as needed */
  }

  .image-items {
    position: relative;
    overflow: hidden;
  }

  .image-items img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    display: block;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .image-items:hover img {
    width: 100%;
    border-radius: 10px;
    height: 98%;
    object-fit: cover;
    display: block;
    cursor: pointer;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }