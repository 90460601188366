.accordion-button:focus{
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
.details{
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.details p {
    font-size: 20px;
    font-weight: 400;
}
.details span {
    font-size: 20px;
    font-weight: 500;
    color:#007aff
}
.details h6{
    font-size: 20px;
    font-weight: 500;
}