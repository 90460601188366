.imgtextCell{
    display: flex;
    justify-content: flex-start;
    align-items: center;
   
}
.imgCell{
    
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 10px;
}
.imgCell img{
    height: 100%;
    width:100%;
    border-radius: 10px;
    object-fit: cover !important;
}

.card-wrapper{
height: max-content;
}
.owl-nav{
    display: flex;
    position: relative;
    top: -175px;
    justify-content: space-between;
}
.owl-prev{
    border: 1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.owl-prev span{
    color:#134faf !important;

    font-size: 50px;
}
.owl-next{
    border: 1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.owl-next span{
    color:#134faf !important;

    font-size: 50px;
}
.owl-dots{
    display: none;
}
/* .owl-carousel .owl-item .acc-btn .accept img,
.owl-carousel .owl-item .acc-btn .reject img {
  display: unset;
  width: unset;
} */

  .owl-stage-outer .owl-stage{ display: flex;
}
  .owl-theme .owl-dots .owl-dot.active span {
    background: #134faf !important;
  }
  .owl-theme .owl-dots .owl-dot span {
    background: #a6a6a6 !important;
  }
  .nft_text_wrap{
    padding:10px
  }
  .nft_flex_wrap{
  display: flex;
  justify-content: space-between;
  }
  .nft_flex_wrap .totals{
    text-align: end;
  }
  .nft_flex_wrap .totals h5{
    font-size: 17px !important;
  }
  .nft_flex_wrap .floors h5{
    font-size: 17px !important;
  }
  .nft_flex_wrap .floors{
    text-align: start;
  }
  @media (min-width:450px) and (max-width:550px){
    .owl-nav{
    top: -200px;
  }
}
@media (max-width:450px){
    .owl-nav{
    top: -250px;
  }
}

.loader img{
  width: 200px;
  height: 200px;
  cursor: no-drop;
}

.loader img::selection{
  background-color: transparent !important;;
}