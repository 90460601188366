.input_div p {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 5px !important;
}

.title_span {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px !important;
}

.input_div span {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px !important;
}

.input_div {
    display: flex;

    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px !important;
}

.file_div {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
}

.MuiDialog-paper::-webkit-scrollbar {
    width: 0.5em !important;
}

.MuiDialog-paper::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/* For other browsers */
.MuiDialog-paper {
    scrollbar-width: none;
}

.MuiDialog-paper::-webkit-scrollbar {
    display: none;
}




.MuiDialog-root::-webkit-scrollbar {
    width: 0.5em !important;
}

.MuiDialog-root::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.23);
}

/* For other browsers */
.MuiDialog-root {
    scrollbar-width: none;
}

.MuiDialog-root::-webkit-scrollbar {
    display: none;
}