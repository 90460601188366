.image-grids{
    border:1px solid;
    width:95%;
    height:95%
}
.image-grid{
border-radius:10px;
  /* border:1px solid rgba(0, 0, 0, 0.4); */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin:10px;
  padding-top: 5px;
}
.icon-div{
    display: flex;
    justify-content: space-between;
}
#image{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.image-grids img{
width: 100%;
    height: 100%;
    object-fit: cover;
}
.offerdiv{
    /* border: 1px solid rgba(0, 0, 0, 0.12); */
    border-radius: 10px;
    padding: 25px;
    margin: 20px;

}
.offerdiv span{
    font-size: 15px;
    color: #0f0f0f;
}

.offerdiv button{
    width:50%;
font-weight: 600;
line-height: 22px;
letter-spacing: 0.01em;
padding: 17px 24px;
font-size: 18px;
border-radius: 10px;
background-color: rgba(18, 18, 18, 0.04);
border: none;
color: rgb(18 18 18);
}

.mk-off .MuiFormControl-root{
    width: 100%;
}

.mk-off {
    margin: 15px 0;
}

.mk-off  input{
    padding: 10px;
}

.mk-off-btn button{
    background: #147fbf !important;
    color: #fff;
    text-transform: capitalize;
}

.mk-off-btn{
    text-align: center;
    margin: 15px 0;
}
.mk-off .MuiOutlinedInput-root{
    height: 55px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}