.banner-small {

    width: 171px;
    height: 171px;
    border-radius: 50%;

    background-position: cover !important;

}


.iconpara {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#smallbanner {
    width: 190px;
    height: 190px;
    border: 10px solid white;
    align-content: center;
    text-align: center;
    
    position: relative;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50%;
    top: -135px;
}

.profileimage{
    margin: -20px 30px -180px 30px;
}
#smallbanner img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
#smallbanner svg {
    color: white;
    font-size: 35px;
    position: absolute;
    top: 63px;
    left: 63px;
    z-index: 10;
   display: none;
}

#profile_banner svg {
    color: black;
    font-size: 55px;
    position: absolute;
    bottom: 130px;
    left: 46%;
    z-index: 10;
    padding: 10px;
    border-radius: 50%;
} 
.icondiv{
    
    height: 360px;
    width: 100%;
    position: absolute;
    background: #6c757d85;
    top: 0px;
    left:0px;
    display: none;
}
.banner-big{
   
    height:360px;
    min-height: 360px;
}
#profile_banner:hover .icondiv {
    display: block;
   
}
#profile_banner:hover .banner-big img {
    filter: blur(1px);
   
}
.banner-big img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#smallbanner:hover .banner-small {
    filter: blur(1px);
  
}

#smallbanner:hover svg {
    display: block;
   
}
.flex-container{
    display: flex;
    flex-wrap: wrap;
}
.input-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
  }
  
  .icon {
    margin-right: 8px; /* Adjust the spacing between the icon and text */
  }
  
  .file-input {
    display: none; /* Hide the actual file input */
  }
  .file-inputs {
    display: none; /* Hide the actual file input */
  }

  .grid_explore_container{
    /* max-width: 1200px; */
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid_explore_containers{
    /* max-width: 1200px; */
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid_explore_container .loader img{
    margin-left: 250px;
  }
  .grid_explore_containers .loader img{
    margin-left: 250px;
  }
  @media (max-width: 1169px) {
    .grid_explore_container .loader img{
      margin-left: 150px;
    }
    .grid_explore_containers .loader img{
      margin-left: 150px;
    }
  }
  
  @media (max-width: 1215px) {
    .grid_explore_container { grid-template-columns: repeat(3, 1fr) !important; }
    .grid_explore_container .loader img{
      margin-left: 0px;
    }
    .grid_explore_containers { grid-template-columns: repeat(4, 1fr) !important; }
    .grid_explore_containers .loader img{
      margin-left: 0px;
    }
  }
  
  @media (max-width: 900px) {
    .grid_explore_container { grid-template-columns: repeat(2, 1fr) !important; }
    .grid_explore_container .loader img{
      margin-left: 0px;
    }
    .grid_explore_containers { grid-template-columns: repeat(3, 1fr) !important; }
    .grid_explore_containers .loader img{
      margin-left: 0px;
    }
  }
  
  @media (max-width: 600px) {
    .grid_explore_container { grid-template-columns: repeat(1, 1fr) !important; }
    .grid_explore_containers { grid-template-columns: repeat(1, 1fr) !important; }
  }